/**
 * Aides access management modal controller
 *
 * @property {string} demande - one-way binding <
 * @property {boolean} showModal - two-way binding =
 */

class AidesAccessManagementModalController {
  /**
   * Controller constructor
   *
   * @param {object} $attrs object that contains component properties
   * @param {object} aidesAccessManagementService aides access management service
   * @param {object} aidesService service containing aides functions
   * @param {object} translateService app translate service
   * @param {object} sanitizerService sanitizer service
   */
  constructor($attrs, aidesAccessManagementService, aidesService, translateService, sanitizerService) {
    this.$attrs = $attrs;
    this.aidesAccessManagementService = aidesAccessManagementService;
    this.aidesService = aidesService;
    this.translateService = translateService;
    this.sanitizerService = sanitizerService;
  }

  /**
   * Called on component initialization
   *
   * @returns {void}
   */
  $onInit() {
    this.messageText = this.sanitizerService.sanitize(
      this.translateService.translateContent('connected.dashboard.aides.accessManagement.modal.info')
    );
  }

  /**
   * Function trigerred when modal is hiding
   *
   * @returns {void}
   */
  resetContext() {
    this.loading = false;
    this.errors.retrieve = false;
    this.errors.update = false;
    this.closeModal();
  }

  /**
   * Closes modal
   *
   * @returns {void}
   */
  closeModal() {
    this.showModal = false;
  }

  /**
   * Event handler on mg-modal hide
   *
   * @returns {void}
   */
  onModalHide() {
    this.resetContext();
    this.onHide();
  }

  /**
   * Saves access management
   *
   * @returns {void}
   */
  saveAccessManagement() {
    let savePromise;

    if (this.restricted) {
      let restrictedLinkedUsers = (this.linkedUsers ?? []).filter(({ href }) => this.restrictedUsers.includes(href));

      // When in depot (aide is REQUESTED), do not save signataire as it is not saved on demande yet (signataire is set on frontend demande, but not in back/database)
      const isDemandeRequested = this.aidesService.isRequested(this.demande);
      const signataireHref = this.demande?.signataire?.href;
      if (isDemandeRequested && signataireHref)
        restrictedLinkedUsers = restrictedLinkedUsers.filter(({ href }) => href !== signataireHref);

      savePromise = this.aidesAccessManagementService.putDemandeRestrictions(this.demande?.id, restrictedLinkedUsers);
    } else {
      savePromise = this.aidesAccessManagementService.deleteDemandeRestrictions(this.demande?.id);
    }

    savePromise
      .then(() => {
        this.onRestrictionUpdate({ isRestricted: this.restricted });
        this.closeModal();
      })
      .catch(() => {
        this.errors.update = true;
      });
  }

  /**
   * Computes if an error happened
   *
   * @returns {boolean} true if component has errors
   */
  hasError() {
    return this.errors?.retrieve || this.errors?.update;
  }

  /**
   * Tells if the userList is loading
   *
   * @returns {boolean} true if userList is loading
   */
  isLoading() {
    return this.loading === true;
  }
}

AidesAccessManagementModalController.$inject = [
  '$attrs',
  'aidesAccessManagementService',
  'aidesService',
  'translateService',
  'sanitizerService',
];

angular.module('aides').component('aidesAccessManagementModal', {
  templateUrl: 'aides/aides-components/aides-restrictions/modal/aides-access-management-modal.html',
  controller: AidesAccessManagementModalController,
  bindings: {
    demande: '<',
    showModal: '=',
    restricted: '=',
    userList: '<',
    restrictedUsers: '<',
    linkedUsers: '<',
    loading: '=',
    errors: '=',
    onRestrictionUpdate: '&',
    onHide: '&',
  },
});
