'use strict';
class JustificationsListDeleteModal {
  constructor(sanitizerService) {
    this.sanitizerService = sanitizerService;
  }

  $onInit() {
    this.message = this.sanitizerService.sanitize(this.resolve.message);
  }
}
JustificationsListDeleteModal.$inject = ['sanitizerService'];

angular.module('justifications.components').component('justificationsListDeleteModal', {
  controller: JustificationsListDeleteModal,
  templateUrl: 'justifications/justifications-components/modal/justifications-list-delete.modal.html',
  bindings: {
    dismiss: '&',
    close: '&',
    resolve: '<',
  },
});
