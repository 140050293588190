import { AppLoader } from '@mgdis/eu-loader';

class SanitizerService {
  #sanitizer = AppLoader.getSanitizerService();
  #sce;

  constructor($sce) {
    this.#sce = $sce;
  }

  /**
   * Sanitize HTML
   *
   * @param {string} unsafeHtml unsafe HTML
   * @returns {string} sanitized HTML
   */
  sanitize(unsafeHtml) {
    return this.#sce.trustAsHtml(this.#sanitizer.sanitize(unsafeHtml));
  }
}

SanitizerService.$inject = ['$sce'];

angular.module('common.services').service('sanitizerService', SanitizerService);
