/**
 * Aides access management controller
 *
 * @property {object} demande - one-way binding <
 * @property {boolean} isRestricted - one-way binding <
 * @property {boolean} isIcon - one-way binding < (computed in $onInit)
 */
class AidesAccessManagementController {
  /**
   * Component controller
   *
   * @param {object} $attrs object that contains component properties
   * @param {object} translateService app translate service
   * @param {object} $q $q service
   * @param {object} aidesAccessManagementService aides access management service
   * @param {object} aidesService service containing aides functions
   * @param {object} sanitizerService sanitizer service
   */
  constructor($attrs, translateService, $q, aidesAccessManagementService, aidesService, sanitizerService) {
    this.$attrs = $attrs;
    this.translateService = translateService;
    this.$q = $q;
    this.aidesAccessManagementService = aidesAccessManagementService;
    this.aidesService = aidesService;
    this.sanitizerService = sanitizerService;
  }

  /**
   * Function trigerred at component initialization
   *
   * @returns {void}
   */
  $onInit() {
    this.isIcon = Object.hasOwn(this.$attrs, 'isIcon') && this.$attrs.isIcon !== 'false';
    this.messageText = this.sanitizerService.sanitize(
      this.translateService.translateContent('teleservice.recapitulatif.accessManagement.description')
    );
    this.initProperties();
    this.loading = false;
    this.isRestricted = this.isRestricted ?? false;
    this.lastChoice = this.isRestricted; // Used to keep last restricted choice when canceling modal
    this.signataireHref = this.demande?.signataire?.href; // Used to exclude if from depot
  }

  /**
   * Set tooltip display to undefined so that we can hide it later
   * ! HACK for mg-tooltip, changing its display value : undefined <-> false, to trigger reactivity
   *
   * @returns {void}
   */
  resetTooltip() {
    this.displayTooltip = undefined;
  }

  /**
   * Init component variables when initializing context (at modal showing)
   *
   * @returns {void}
   */
  initProperties() {
    this.resetTooltip();
    this.showModal = false;
    this.restrictedUsers = [];
    this.linkedUsers = [];
    this.userList = [];
    this.errors = {
      retrieve: false,
      update: false,
    };
  }

  /**
   * Opens modal
   *
   * @returns {void}
   */
  openModal() {
    this.fetchUsersAndRestrictions();
    this.lastChoice = this.isRestricted;
    this.showModal = true;
    this.displayTooltip = false;
  }

  /**
   * Function trigerred when modal is showing, fetching if demande is restricted, restricted users and linked users
   *
   * @returns {void}
   */
  fetchUsersAndRestrictions() {
    if (this.demande?.id) {
      this.loading = true;
      this.initProperties();

      this.aidesAccessManagementService
        .fetchUsersAndRestrictions(this.demande, this.demande?.demandeur?.expand?.reference, this.signataireHref)
        .then(({ isRestricted, linkedUsers, userList, restrictedUsers }) => {
          this.updateRestriction(isRestricted);
          this.linkedUsers = linkedUsers;
          this.userList = userList;
          this.restrictedUsers = restrictedUsers;
        })
        .catch(() => {
          this.errors.retrieve = true;
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.errors.retrieve = true;
    }
  }

  /**
   * Update isRestricted property according to update event from modal
   *
   * @param {boolean} isRestricted true is the saved choice is selecting some accounts
   * @returns {void}
   */
  updateRestriction(isRestricted) {
    this.isRestricted = isRestricted;
    this.lastChoice = this.isRestricted;
  }

  /**
   * Reset choice when we cancel modal
   *
   * @returns {void}
   */
  resetChoice() {
    this.isRestricted = this.lastChoice;
  }

  /**
   * Handles on-hide event from modal
   *
   * @returns {void}
   */
  onHide() {
    this.resetTooltip();
    this.resetChoice();
  }

  /**
   * Check if button is disabled or not
   *
   * @returns {boolean} true if the button is disabled
   */
  isButtonDisabled() {
    return this.loading && !this.showModal;
  }

  /**
   * Computes if restricted button (lock icon) should be displayed
   * Means : modal hidden --> isRestricted, modal shown --> lastChoice
   *
   * @returns {boolean} true if restricted button should be shown
   */
  showRestrictedButton() {
    return (!this.showModal && this.isRestricted) || (this.showModal && this.lastChoice);
  }

  /**
   * Computes if not restricted button (unlock icon) should be displayed
   * Means : modal hidden --> !isRestricted, modal shown --> !lastChoice
   *
   * @returns {boolean} true if not restricted button should be shown
   */
  showNotRestrictedButton() {
    return (!this.showModal && !this.isRestricted) || (this.showModal && !this.lastChoice);
  }
}

AidesAccessManagementController.$inject = [
  '$attrs',
  'translateService',
  '$q',
  'aidesAccessManagementService',
  'aidesService',
  'sanitizerService',
];

angular.module('aides').component('aidesAccessManagement', {
  templateUrl:
    'aides/aides-components/aides-restrictions/aides-access-management/aides-access-management.component.html',
  controller: AidesAccessManagementController,
  bindings: {
    demande: '<',
    isRestricted: '<',
  },
});
